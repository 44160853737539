import styled from 'styled-components';
import React, { useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { colors } from '../../../styles/colors';
import Card from '../../molecules/cards/card';
import TripHeader from '../../molecules/trip-header';
import { Button } from '../../atoms';
import useApiCall from '../../../utils/hooks/useApiCall';
import { fetchSingleTripApi } from '../../../services/trips';
import PageLoader from '../../molecules/page-loader';
import useAuth from '../../../utils/hooks/useAuth';
import { Typography } from '../../../styles';

const FollowingTripWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${colors.main_color};

  .following_wrapper {
    background-color: ${colors.white};
    border-radius: 20px;
    width: 95%;
  }
  .form_section {
    background-color: ${colors.white};
    margin-bottom: 15px;
  }
  .text {
    color: ${colors.greyText};
    font-size: 16px;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
    line-height: 18px;
    text-align: center;
    font-weight: 400;
  }
  .button_section {
    text-align: center;
    margin-top: 15px;
  }
  .button {
    border-radius: 25px;
    padding: 10px 30px 10px 30px;
  }
  .card_wrapper {
    background-color: ${colors.faded_grey};
    padding: 20px;
  }
  .button_connect {
    background-color: ${colors.white};
    border: 1px solid ${colors.main_color};
    color: ${colors.main_color};
    border-radius: 20px;
  }
  .title_section {
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px 20px;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
    line-height: 18px;
  }
  .email_text {
    color: ${colors.black};
    font-size: 16px;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
    line-height: 18px;
    text-align: center;
    font-weight: 400;
  }
  .text_section {
    font-size: 22px;
    text-align: center;
    line-height: 22px;
    margin-bottom: 10px;
    font-weight: 700;
    color: ${colors.main_color};
  }
  .plan_section {
    background-color: ${colors.yellow};
    padding: 25px 35px;
  }
  .heading_section {
    padding: 20px 10px;
  }
  .no_thanks {
    text-align: center;
    margin-top: 20px;
    color: ${colors.grey};
    font-size: 14px;
    text-decoration: underline;
    cursor: pointer;
  }
  @media screen and (min-width: 500px) {
    .following_wrapper {
      margin: 25px;
      width: auto;
    }
  }
`;

const FollowedTrip = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { guestUser } = useAuth();

  const [searchParams] = useSearchParams();
  const slug = searchParams.get('userSlug');
  const time = searchParams.get('token');
  const { t } = useTranslation();

  const [trip, tripLoading, tripCall] = useApiCall(fetchSingleTripApi);

  useEffect(() => {
    const obj = {
      id: params.tripId,
      token: time,
      userSlug: slug,
    };
    tripCall(obj);
  }, []);

  if (tripLoading) {
    return <PageLoader />;
  }
  return (
    <>
      <FollowingTripWrapper>
        <div className='following_wrapper'>
          <TripHeader
            onClose={() => navigate(`/trips/${slug}?token=${time}&guestEmail=${guestUser?.email}`)}
          />
          <div className='title_section'>
            {t('app.followingTrip')} <br /> {trip?.trip?.userId?.fullName}'s {t('app.trip')}
          </div>
          <div className='card_wrapper'>
            <Card
              endTime={trip?.trip?.endTime}
              startTime={trip?.trip?.startTime}
              country={trip?.trip?.country}
              city={trip?.trip?.city}
              startDate={trip?.trip?.startDate}
              endDate={trip?.trip?.endDate}
            />
          </div>
          <div className='form_section'>
            <div className='heading_section'>
              <div className='text'>{t('app.receiveUpdates')}</div>
              <div className='email_text'>{guestUser.email}</div>
            </div>
            <div className='plan_section'>
              <div className='text_section'>
                {t('app.wantToPlan')} <br />
                {t('app.catchup', { name: trip?.trip?.userId?.fullName })}
              </div>
              <div className='button_section'>
                <Button className='button' onClick={() => navigate(`/sign-up`)}>
                  {t('app.createAccount')}
                </Button>
              </div>
            </div>

            <div
              className='no_thanks'
              onClick={() =>
                navigate(`/trips/${slug}?token=${time}&guestEmail=${guestUser?.email}`)
              }
            >
              {t('app.noThanks')}
            </div>
          </div>
        </div>
      </FollowingTripWrapper>
    </>
  );
};

export default FollowedTrip;
