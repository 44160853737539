import styled from 'styled-components';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

import Button from 'components/atoms/Button/index';
import { colors } from '../../../styles/colors';
import { Typography } from '../../../styles';

const CatchUpCardWrapper = styled.div`
  background-color: ${colors.faded_grey};
  .inside_wrapper_res {
    max-width: 450px;
    background-color: ${colors.white};
    padding: 20px;
    border-radius: 15px;
    margin-bottom: 15px;
  }
  .uper_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .lower_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .divider {
    width: 40px;
  }
  .trips_title {
    font-size: 15px;
    font-weight: 700;
    line-height: 22px;
    color: ${colors.black};
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
    max-width: 300px;
  }
  .trips_date {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    font-family: ${Typography.FONT_FAMILY_ROBOTO};
  }
  .button_section {
    height: 28px;
  }
  .button_section_disabled {
    height: 28px;
    background-color: ${colors.light_grey};
    color: ${colors.white};
  }
`;

const CatchUpCard = ({
  name,
  formattedAddress,
  startDate,
  startTime,
  isJoin,
  onButtonClick,
  isButton = true,
}) => {
  const { t } = useTranslation();

  const formatDateTime = (input, format, isTime = false) => {
    try {
      return isTime
        ? dayjs(input, 'HH:mm:ss').format(format)
        : dayjs(input, 'HH:mm').format(format);
    } catch (error) {
      return isTime ? 'Invalid time' : 'Invalid date';
    }
  };

  return (
    <CatchUpCardWrapper>
      <div className='inside_wrapper_res'>
        <div className='uper_wrapper'>
          <div className='trips_title'>
            {name},&nbsp;{formattedAddress}
          </div>
        </div>
        <div className='lower_wrapper'>
          <div className='trips_date'>
            {formatDateTime(startDate, 'DD MMM YYYY')}&nbsp;
            {formatDateTime(startTime, 'HH:mm')}
          </div>
          {isButton && (
            <>
              {isButton && isJoin ? (
                <Button disabled className='button_section_disabled'>
                  {t('app.joined')}
                </Button>
              ) : (
                <Button className='button_section' onClick={onButtonClick}>
                  {t('app.join')}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
    </CatchUpCardWrapper>
  );
};

export default CatchUpCard;
